code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// Mantine overrides

// Other styles

// Giving mantine table first column some padding

// .datatable tr th:first-child .mantine-TableHeadCell-Content,
// .datatable tr th:first-child .mantine-InputWrapper-root {
//     margin-left: 25px;

// }

// .datatable tbody tr td:first-child {
//     padding-left: 40px;
// }

// .datatable {
//     right: 10px;
// }

// .datatable .datatable-row.active {
//     background-color: saddlebrown;
// }

.mantine-Container-root {
  padding: 0px;
}

// Normal table

.normal-table th {
  font-weight: 700 !important;
}

// hover table

.hover-table tr:hover {
  cursor: pointer;
}

// form inputs
.mantine-InputWrapper-error,
.mantine-InputWrapper-description {
  font-size: 13px;
}

.mantine-Breadcrumbs-root {
  overflow: hidden;
}
